





























































import { Component, Vue, Watch } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import { ShopModule } from '@/store/modules'

@Component({
  components: {
    VueSlider
  }
})
export default class ShopFilter extends Vue {
  @ShopModule.Action getShopItems
  @ShopModule.Getter highestItemPrice
  @ShopModule.Mutation setSearchItemName
  @ShopModule.Mutation setSearchCategoryIds
  @ShopModule.Mutation setSearchPriceMax
  @ShopModule.Mutation setSortBy
  @ShopModule.Mutation resetFilters
  @ShopModule.State shopCategories

  categories = []
  categoriesSelected = []

  price = 0

  sortBy = ''
  sortTypes = [
    { id: 0, text: 'Alphabetical (A-Z)', value: 'alphaUp' },
    { id: 1, text: 'Alphabetical (Z-A)', value: 'alphaDown' },
    { id: 2, text: 'Price (ascending)', value: 'priceUp' },
    { id: 3, text: 'Price (descending)', value: 'priceDown' }
  ]

  search = ''
  loading = false

  @Watch('search')
  onSearchItemChange(name) {
    this.setSearchItemName(name)
  }

  @Watch('price')
  onPriceMaxChange(priceMax) {
    this.setSearchPriceMax(priceMax)
  }

  @Watch('sortBy')
  onSortByChange(value) {
    this.setSortBy(value)
  }

  @Watch('categoriesSelected')
  onCategoriesSelectedChange(ids) {
    this.setSearchCategoryIds(ids)
  }

  async created() {
    this.categories = this.shopCategories.map(e => {
      return {
        id: e.id,
        text: e.name,
        value: e.id
      }
    })
  }

  async mounted() {
    this.setSearchItemName('')
    this.reset()
  }

  reset() {
    this.loading = true
    this.categoriesSelected = []
    this.price = 0
    this.sortBy = 'reset'
    this.search = ''

    setTimeout(() => {
      this.loading = false
    }, 500)
  }
}
